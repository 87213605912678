import * as CartService from 'mycs/services/CartService';
import {
  Cart,
  LineItem,
  postCart,
  PostCartPayloadLineItem,
} from 'mycs/api/CheckoutAPI';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import { useCallback } from 'react';
import { CfMaterial } from '@mycs/contentful';

export function useCartTools(
  cart: undefined | Cart,
  setCart: (cart: Cart) => void
) {
  const { locale, countryCode, languageCode } = useLocale();

  const updateCart = useCallback(
    async function (
      updateLineItems:
        | ((_: PostCartPayloadLineItem[]) => PostCartPayloadLineItem[])
        | null,
      couponCode: string | undefined = cart?.couponCode
    ) {
      const postCartPayloadLineItems = CartService.toPostCartPayloadLineItems(
        cart?.lineItems || []
      );
      const lineItems = updateLineItems
        ? updateLineItems(postCartPayloadLineItems)
        : postCartPayloadLineItems;

      const updatedCart = await postCart({
        countryCode,
        couponCode,
        lineItems,
        meta: {
          language: languageCode,
        },
      });

      setCart(updatedCart);

      return updatedCart;
    },
    [cart, setCart, countryCode, languageCode]
  );

  const addAssemblyService = useCallback(
    async function (lineItemIndex: number) {
      return updateCart((items) =>
        CartService.addAssemblyService(items, lineItemIndex)
      );
    },
    [updateCart]
  );

  const toggleBulkAssemblyService = useCallback(
    async function (shouldAdd: boolean, lineItemUUIDs: string[]) {
      updateCart((items) => {
        return CartService.toggleBulkAssemblyService(
          shouldAdd,
          lineItemUUIDs,
          items
        );
      });
    },
    [updateCart]
  );

  const removeAssemblyService = useCallback(
    async function (lineItemIndex: number) {
      return updateCart((items) =>
        CartService.removeAssemblyService(items, lineItemIndex)
      );
    },
    [updateCart]
  );

  const updateLineItemQuantity = useCallback(
    async function (lineItemIndex: number, quantity: number) {
      return updateCart((items) =>
        CartService.updateLineItemQuantity(items, lineItemIndex, quantity)
      );
    },
    [updateCart]
  );

  const addLineItem = useCallback(
    async function (
      uuid: string,
      quantity: number,
      baseboardCutMeta?: {
        depth: number;
        height: number;
      }
    ) {
      return updateCart((items) => {
        return CartService.addLineItem(
          locale,
          items,
          uuid,
          quantity,
          baseboardCutMeta
        );
      });
    },
    [locale, updateCart]
  );

  const restoreLineItem = useCallback(
    async function (lineItem: LineItem, index: number) {
      return updateCart((items) =>
        CartService.restoreLineItem(items, lineItem, index)
      );
    },
    [updateCart]
  );

  const updateCoupon = useCallback(
    async function (couponCode: string) {
      return updateCart(null, couponCode);
    },
    [updateCart]
  );

  const addOrUpdateSampleBox = useCallback(
    async function (sampleBoxItems: CfMaterial[]) {
      return updateCart((items) =>
        CartService.addOrUpdateSampleBox(locale, items, sampleBoxItems)
      );
    },
    [locale, updateCart]
  );

  const restoreCart = useCallback(
    async function (uuidQueryString: string) {
      return updateCart(() => {
        // Should reset the current cart.
        let items: PostCartPayloadLineItem[] = [];
        // TODO There might be "," at the end of the "uuid" query value.
        // https://de.mycs.com/warenkorb?uuid=zHMo80bEV,Fi6v1CyfZ,&utm_medium...
        const uuids = uuidQueryString.split(',').filter((uuid) => uuid);
        const uuidQuantityMap = CartService.getUUIDQuantityMap(
          uuids.map((uuid) => ({ uuid, quantity: 1 }))
        );

        Object.entries(uuidQuantityMap).forEach(([uuid, quantity]) => {
          items = CartService.addLineItem(locale, items, uuid, quantity);
        });

        return items;
      });
    },
    [locale, updateCart]
  );

  return {
    addAssemblyService,
    removeAssemblyService,
    updateLineItemQuantity,
    addLineItem,
    restoreLineItem,
    updateCoupon,
    addOrUpdateSampleBox,
    restoreCart,
    toggleBulkAssemblyService,
  };
}
