import { noop } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import { useLocale } from 'mycs/shared/state/LocaleContext';
import Button from 'mycs/shared/components/Button/Button';
import ClickOutside from 'mycs/shared/components/ClickOutside/ClickOutside';
import I18nUtils from 'mycs/shared/utilities/I18nUtils/I18nUtils';

import styles from './Tooltip.scss';

type Props = {
  content: React.ReactNode;
  hint?: boolean;
  hasCloseButton?: boolean;
  contentClickClose?: boolean;
  onClose: () => void;
  clickOutsideWhiteList?: any[];
};

export function TooltipContent({
  contentClickClose,
  hasCloseButton,
  hint,
  clickOutsideWhiteList = [],
  content,
  onClose,
}: Props) {
  const { locale } = useLocale();

  const closeButton = hasCloseButton ? (
    <span className={styles.closeIcon} onClick={onClose}>
      <Button iconName="general/close" />
    </span>
  ) : null;

  const safeHtml =
    hint && content
      ? typeof content === 'string'
        ? I18nUtils.localize(locale, content)
        : content
      : '';

  return (
    <ClickOutside
      onClickOutside={onClose}
      clickOutsideWhiteList={clickOutsideWhiteList}
    >
      <div
        className={hint ? '' : styles.content}
        onClick={contentClickClose ? onClose : noop}
      >
        {hint ? (
          typeof safeHtml === 'string' ? (
            <div
              className={'tooltip-inner'}
              dangerouslySetInnerHTML={{ __html: safeHtml }}
            />
          ) : (
            <div className={'tooltip-inner'}>{safeHtml}</div>
          )
        ) : (
          content
        )}
        {closeButton}
      </div>
    </ClickOutside>
  );
}
