import { Coupon } from 'mycs/api/CheckoutAPI';
import { RelativeUrlService } from 'mycs/shared/services/RelativeUrlService';
import AnalyticsService from 'mycs/shared/services/AnalyticsService/AnalyticsService';

export type CheckoutEventTagInfo = {
  locationPathname: string;
  countryCode: string;
  currencyCode: string;
  products: {
    name: string;
    id: string;
    price: string;
    brand: string;
    category: string; //furnitureType,
    variant: string;
    quantity: number;
    cogs: number;
    logistics: number;
    payment_rate: number;
  }[];
  quantity: number;
  total: number;
  couponCode?: string;
  coupon?: Coupon;
  discountTotal: string;
  shippingCost: string;
  orderTotal: string;
  taxTotal: string;
  transaction_id: string;
};

export type OrderSucessEventTagInfo = {
  locationPathname: string;
  countryCode: string;
  currencyCode: string;
  products: {
    name: string;
    id: string;
    price: string;
    brand: string;
    category: string; //furnitureType,
    variant: string;
    quantity: number;
    cogs: number;
    logistics: number;
    payment_rate: number;
  }[];
  couponCode: string;
  shipping: string;
  revenue: string;
  tax: string;
  id: string;
};

export type ProductCartTagInfo = {
  products: {
    category: string; // e.g. 'joyn'
    currency: string; // e.g. 'EUR'
    id: string; // e.g. design UUID 'vV3Lo_8WR'
    image: string; // image URL
    name: string; // localized product title
    price: number; // one item price
    quantity: number; // item quantity
    url: string; // PDP URL
  }[];
  total: number; // cart/order total
};

export type AddToCartTagInfo = {
  countryCode: string;
  currencyCode: string;
  products: {
    id: string; // e.g. design UUID 'vV3Lo_8WR'
    name: string; // localized product title
    price: number; // one item price
    brand: string; // passed empty
    category: string; // e.g. 'joyn'
    variant: string; // passed empty
    quantity: number; // item quantity
  }[];
};

export const CheckoutAnalyticsService = {
  trackCheckoutEvent(
    stepID: number,
    optionName: string,
    tagInfo: CheckoutEventTagInfo,
    locale: string
  ) {
    const tag = CheckoutEventsTagBuilder.buildCheckoutEventTag(
      stepID,
      optionName,
      tagInfo,
      locale
    );

    AnalyticsService.eventTrack('checkout', locale, tag);
  },
  trackOrderSuccesEvent(
    isMaterialsSamplePurchase: boolean,
    orderSuccessTagInfo: OrderSucessEventTagInfo,
    locale: string
  ) {
    // Send a GoogleAnalytic "success" event
    const orderSuccessEventName = isMaterialsSamplePurchase
      ? 'success_samples'
      : 'success-pv';
    const orderSuccessTag = CheckoutEventsTagBuilder.buildOrderSuccessTag(
      orderSuccessEventName,
      orderSuccessTagInfo,
      locale
    );
    AnalyticsService.eventTrack(orderSuccessEventName, locale, orderSuccessTag);
  },
  trackProductCartEvent(
    locale: string,
    productCartTagInfo: ProductCartTagInfo
  ) {
    const tag =
      CheckoutEventsTagBuilder.buildProductCartTag(productCartTagInfo);
    AnalyticsService.eventTrack('productCart', locale, tag);
  },
  trackAddToCartEvent(addToCartTagInfo: AddToCartTagInfo, locale: string) {
    const tag = CheckoutEventsTagBuilder.buildAddToCartTag(addToCartTagInfo);

    AnalyticsService.eventTrack('addToCart', locale, tag);
  },
  sendWelcomeEmail(locale: string) {
    // On newsletter subscription, send a GoogleAnalytic "welcomeMail" event
    AnalyticsService.sendWelcomeMailEvent(locale);
  },
};

const CheckoutEventsTagBuilder = {
  buildCheckoutEventTag(
    stepID: number,
    stepName: string,
    {
      countryCode,
      locationPathname,
      currencyCode,
      products,
      quantity,
      total,
      discountTotal,
      orderTotal,
      taxTotal,
      transaction_id,
      coupon,
    }: CheckoutEventTagInfo,
    locale: string
  ) {
    const coupons: any = {};

    if (coupon?.code) {
      coupons[coupon.code] = {
        id: coupon.code,
        name: coupon.campaignName,
        amount: discountTotal,
        is_applied: coupon.redeemed,
        is_taxable: true,
      };
    }

    return {
      event: 'checkout',
      originalPageURL: RelativeUrlService.getUntranslatedUrl(
        locationPathname,
        locale
      ),
      pageURL: locationPathname,
      pageCategory: 'checkout',
      pageCountry: countryCode?.toUpperCase(),
      currencyCode,
      ecommerce: {
        checkout: {
          actionField: {
            step: stepID,
            option: stepName,
          },
          products,
          quantity,
          total,
        },
      },
      step: stepName,
      coupons,
      discountTotal: discountTotal,
      orderTotal: orderTotal,
      taxTotal: taxTotal,
      transaction_id,
    };
  },
  buildOrderSuccessTag(
    eventName: string,
    {
      locationPathname,
      countryCode,
      currencyCode,
      id,
      revenue,
      tax,
      shipping,
      couponCode,
      products,
    }: OrderSucessEventTagInfo,
    locale: string
  ) {
    return {
      event: eventName,
      originalPageURL: RelativeUrlService.getUntranslatedUrl(
        locationPathname,
        locale
      ),
      pageURL: locationPathname,
      pageCategory: 'success',
      pageCountry: countryCode?.toUpperCase(),
      currencyCode,
      ecommerce: {
        purchase: {
          actionField: {
            id,
            affiliation: countryCode?.toUpperCase(),
            revenue,
            tax,
            shipping,
            coupon: couponCode,
          },
          products,
        },
      },
    };
  },
  buildProductCartTag({ products, total }: ProductCartTagInfo) {
    return {
      event: 'productCart',
      products,
      total,
    };
  },
  buildAddToCartTag({ countryCode, products, currencyCode }: AddToCartTagInfo) {
    return {
      event: 'addToCart',
      pageCategory: 'cart',
      pageCountry: countryCode.toUpperCase(),
      ecommerce: {
        currencyCode,
        add: {
          products,
        },
      },
    };
  },
};
