import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { usePopper } from 'react-popper';
import classNames from 'classnames';

import { useDevice } from 'mycs/router/DeviceContext';
import { TooltipContent } from './TooltipContent';

import styles from './Tooltip.scss';

export type TooltipPosition = 'top' | 'bottom' | 'right' | 'left';

type Props = {
  /** Element that triggers the tooltip */
  children?: React.ReactNode;
  /** Content of tooltip */
  content: React.ReactNode | string;
  /** Position of the tooltip */
  placement?: TooltipPosition;
  isMobileOverlay?: boolean;
  className?: string;
  childrenClassName?: string;
  show: boolean;
  hint?: boolean;
  disableOnClose?: boolean;
  hasCloseButton?: boolean;
  contentClickClose?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  clickOutsideWhiteList?: any[];
};

export default function Tooltip({
  className,
  childrenClassName,
  children,
  isMobileOverlay = false,
  placement,
  hint,
  onClose,
  show,
  onOpen,
  contentClickClose,
  hasCloseButton,
  clickOutsideWhiteList,
  content,
  disableOnClose,
}: Props) {
  const { isIos } = useDevice();
  const [isMounted, setIsMounted] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);

  const popper = usePopper(referenceElement, popperElement, {
    placement: placement ?? 'auto',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        enabled: true,
        options: {
          offset: [0, 8],
        },
      },
    ],
  });

  /**
   * Remove body style when unmounting
   */
  useEffect(() => {
    return () => {
      // fix safari input bug
      if (isIos) {
        //@ts-ignore
        document.body.style.position = null;
      }
    };
  }, [isIos]);

  useEffect(() => {
    setIsMounted(true);

    if (!hint) show ? onOpen?.() : onClose?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * change open state if show props changes
   */
  useEffect(() => {
    if (hint) {
      toggleOverlay(show);
    }

    if (isOpen !== show) {
      setIsOpen(show);
      if (!hint) {
        if (show) {
          onOpen?.();
        } else {
          onClose?.();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    if (!isMounted || !popperElement) {
      return;
    }

    if (isOpen) {
      popperElement.setAttribute('data-show', '');
    } else {
      popperElement.removeAttribute('data-show');
    }

    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    popper.update?.();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popperElement, isOpen, isMounted]);

  /**
   * Click-outside and close button handler
   */
  const close = () => {
    // Pass disable on close
    // for more control
    // e.g. on triggering on hover, might not be desirable
    if (isOpen && !disableOnClose) {
      setIsOpen(false);
      onClose?.();
    }
  };

  const spanClasses = classNames(childrenClassName, {
    [styles.toggle]: !hint,
  });

  const childrenContent = (
    <span className={spanClasses} ref={setReferenceElement}>
      {children}
    </span>
  );

  if (!isMounted) return childrenContent;

  // When mounted, render the actual popover
  const popoverClasses = classNames(styles.popover, className, {
    [styles.mobileOverlay]: isMobileOverlay && isOpen,
    hint: hint,
    tooltip: hint,
  });

  const popperStyle = {
    ...popper.styles.popper,
    opacity: isOpen ? 1 : 0,
    zIndex: isOpen ? 99 : 0,
    transform: isOpen ? popper.styles.popper.transform : 'none',
    display: isOpen ? 'block' : 'none',
  };

  return (
    <>
      {childrenContent}

      {isOpen &&
        createPortal(
          <div
            ref={setPopperElement}
            style={popperStyle}
            {...popper.attributes.popper}
            className={popoverClasses}
            data-testid="tooltip"
          >
            <TooltipContent
              contentClickClose={contentClickClose}
              hasCloseButton={hasCloseButton}
              hint={hint}
              clickOutsideWhiteList={clickOutsideWhiteList}
              content={content}
              onClose={close}
            />
            <div
              className={styles.popoverArrow}
              ref={setArrowElement}
              style={popper.styles.arrow}
            />
          </div>,
          document.body
        )}
    </>
  );
}

/**
 * Function to toggle overlay
 */
function toggleOverlay(show: boolean) {
  if (show) {
    document.body.classList.add('hints-overlay');
  } else {
    setTimeout(() => {
      document.body.classList.remove('hints-overlay');
    }, 200);
  }
}
