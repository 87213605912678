import { PureComponent } from 'react';

import { noop } from 'mycs/shared/utilities/GeneralUtils/GeneralUtils';
import Alink from 'mycs/shared/components/Alink/Alink';
import Icon from 'mycs/shared/components/Icon/Icon';
import UIUtils from 'mycs/shared/utilities/UIUtils/UIUtils';

type Props = {
  className?: string;
  onClick?: (destination: string) => void;
  destination: string;
  iconClassName?: string;
  iconContainerClassName?: string;
  iconName?: string;
  offset?: number;
  children?: React.ReactNode;
  text?: React.ReactNode;
};

export default class Anchor extends PureComponent<Props> {
  static defaultProps = {
    offset: 0,
    onClick: noop,
  };

  /**
   * Handle the click event
   */
  onClick = () => {
    const hash = this.getDestination();

    let duration = 1500;
    UIUtils.scrollToElement(this.props.destination, {
      offset: this.props.offset,
      duration,
    });

    if (hash) {
      setTimeout(() => {
        window.location.hash = hash;
      }, duration);
    } else {
      window.history.pushState(null, '', ' ');
    }

    this.props.onClick?.(this.props.destination);
  };

  /**
   * Return the anchor destination (hash)
   */
  getDestination() {
    const { destination } = this.props;
    return destination[0] === '#' ? destination : '';
  }

  render() {
    return (
      <Alink
        className={this.props.className}
        onClick={this.onClick}
        text={this.props.destination}
      >
        {this.props.text || this.props.children}

        {this.props.iconName ? (
          <Icon
            iconContainerClass={this.props.iconContainerClassName}
            className={this.props.iconClassName}
            iconName={this.props.iconName}
            isInline
          />
        ) : null}
      </Alink>
    );
  }
}
