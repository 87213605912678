import { BrowserService } from 'mycs/shared/services/BrowserService/BrowserService';
import { CfAsset, CfResponsiveImageLike } from '@mycs/contentful';
import MobileDetectService from 'mycs/shared/services/MobileDetectService/MobileDetectService';
import UrlProviderService from 'mycs/shared/services/UrlProviderService/UrlProviderService';

class ContentfulUtils {
  /**
   *
   * @param data
   * @returns
   * @deprecated not SSR friendly, prefer useDevice
   */
  static imageSelect(data?: CfResponsiveImageLike): CfAsset | null {
    if (!data) return null;

    const { image, imageTablet, imagePhone } = data;
    let resultImage = image;

    if (BrowserService.isSmallScreen()) {
      resultImage = imagePhone || imageTablet || resultImage;
    } else if (
      MobileDetectService.isMobile() &&
      !BrowserService.isLandscape()
    ) {
      resultImage = imageTablet || resultImage;
    }

    if (!resultImage) {
      return null;
    }

    return {
      ...resultImage,
      description: ContentfulUtils.getDescription(
        resultImage,
        imageTablet,
        imagePhone
      ),
    };
  }

  /**
   *
   * @param data
   * @returns
   * @deprecated not SSR friendly, prefer useDevice
   */
  static videoSelect(
    video: CfAsset,
    videoTablet?: CfAsset,
    videoMobile?: CfAsset
  ): CfAsset {
    let resultVideo = video;

    if (BrowserService.isSmallScreen()) {
      resultVideo = videoMobile || videoTablet || resultVideo;
    } else if (
      MobileDetectService.isMobile() &&
      !BrowserService.isLandscape()
    ) {
      resultVideo = videoTablet || resultVideo;
    }

    return resultVideo;
  }

  static getDescription(
    image?: CfAsset,
    imageTablet?: CfAsset,
    imagePhone?: CfAsset
  ): string {
    if (image && image.description) return image.description;
    if (imageTablet && imageTablet.description) return imageTablet.description;
    if (imagePhone && imagePhone.description) return imagePhone.description;
    if (image && image.title) return image.title;
    if (imageTablet && imageTablet.title) return imageTablet.title;
    if (imagePhone && imagePhone.title) return imagePhone.title;
    return '';
  }

  /**
   * 1. Find the needed image based on current device
   * 2. Routes the url through our Image Processing API
   * 3. Use Webp as the output format
   * 4. Assign Image API img path to assetUrl
   */
  static getImage(data?: CfResponsiveImageLike): CfAsset | null {
    const img = ContentfulUtils.imageSelect(data);

    if (!img || !img.url) {
      return img;
    }

    return {
      ...img,
      assetUrl: UrlProviderService.getImageUrl(img.url, img.width, img.height),
    };
  }
}

export default ContentfulUtils;
